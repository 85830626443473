<template>
  <div class="bbr-workouts--active-workouts">
    <workouts-list-table
      :workouts="workouts"
      :loading="loading"
      :meta="listMeta"
      @goTo="showDetails"
      @loadMore="loadMore"
    />
  </div>
</template>

<script>
import WorkoutsListTable from '../components/WorkoutsListTable'
import { mapState, mapActions, mapMutations } from 'vuex'
import Event from '@/services/eventBus'

export default {
  name: 'ActiveWorkouts',

  components: {
    WorkoutsListTable,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.workouts.filter,
      workouts: (state) => state.workouts.active.list,
      listMeta: (state) => state.workouts.active.listMeta,
    }),
  },

  created() {
    this.setFilter({ active: true })

    if (!this.filter.search) {
      this.clearList()
      this.fetchWorkouts()
    }

    Event.$on('new-workout-added', (type) => {
      if (type) {
        this.fetchWorkouts()
      }
    })
  },

  methods: {
    ...mapActions({
      getWorkouts: 'workouts/getWorkouts',
    }),

    ...mapMutations({
      setFilter: 'workouts/setFilter',
      clearList: 'workouts/clearList',
    }),

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchWorkouts(this.listMeta.current_page + 1)
      }
    },

    async fetchWorkouts(page = 1) {
      if (this.loading) return

      let active = true
      let search = this.filter.search
      let params = { page, search, active }

      this.loading = true

      await this.getWorkouts(params)

      this.loading = false
    },

    showDetails(workoutId) {
      this.$router.push({
        name: 'workout',
        params: {
          id: workoutId,
        },
      })
    },
  },
}
</script>
