<template>
  <div>
    <v-simple-table>
      <thead v-if="workouts.length">
        <tr>
          <th class="text-left" width="25%">Title</th>
          <th class="text-left">Instructions</th>
          <th class="text-left">Trainer</th>
          <th class="text-left">Tags</th>
          <th class="text-left">Seed Tracker</th>
        </tr>
      </thead>
      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
      >
        <tr
          v-for="workout in workouts"
          :key="workout.id"
          class="clickable new-tab-link-row"
        >
          <td v-list-link="setListLink(workout.id)">
            {{ workout.title }}
          </td>
          <td v-list-link="setListLink(workout.id)" style="width: 45%">
            {{ workout.description }}
          </td>
          <td v-list-link="setListLink(workout.id)">
            <span v-if="workout.trainer"> {{ workout.trainer.name }} </span>
          </td>
          <td v-list-link="setListLink(workout.id)">
            {{ workout.tags | readable }}
          </td>
          <td v-list-link="setListLink(workout.id)">
            {{ workout.seed_tracker }}
          </td>
        </tr>
      </tbody>

      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list
      v-if="!workouts.length && !loading"
      details="No Available Workouts"
    />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

import { mdiDotsVertical } from '@mdi/js'
import { includes } from 'lodash'

export default {
  name: 'WorkoutListTable',

  directives: {
    infiniteScroll,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    workouts: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
  },

  components: {
    TableSkeletonLoader,
    NoList,
  },

  data() {
    return {
      icons: {
        action: mdiDotsVertical,
      },
    }
  },

  computed: {
    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },

    isActiveList() {
      return includes(this.$route.name, 'active')
    },

    redirectPathName() {
      return includes(this.$route.name, 'active')
        ? 'workout.details'
        : 'new.workout'
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    goTo(workout) {
      this.$emit('goTo', workout.id)
    },

    setListLink(workoutId) {
      return {
        id: workoutId,
        routeName: this.redirectPathName,
        fullPath: this.isActiveList
          ? `/workouts/${workoutId}/details`
          : `/workouts/new/${workoutId}/details`,
      }
    },
  },
}
</script>
